import { lazy } from "react";

import HeaderAnimation from "./HeaderAnimation";
import Loading from "./Loading";
import GridSystems from "./GridSystems";
import HeaderSystem from "./HeaderSystem";
import Button from "./Button";
import TableScene from "./TableScene";
import TextRollEmpty from "./TextRollEmpty"
import Carousel from "./Carousel"

const Header = lazy(async () => await import("./Header"));

export {
  Header,
  HeaderAnimation,
  Loading,
  GridSystems,
  HeaderSystem,
  Button,
  TableScene,
  TextRollEmpty,
  Carousel
};
