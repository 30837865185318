import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
`;

export const TextOverlay = styled.div`
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -20%);
  z-index: 2;
  text-align: center;
  color: white;
  justify-content: center;
`;

export const Title = styled.h2`
  font-size: 5rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  font-family: "Pirata One", serif;
  font-weight: 400;
  font-style: normal;

  @media (max-width: 995px) {
    font-size: 4rem;
  }
  @media (max-width: 705px) {
    font-size: 3rem;
  }

  @media (max-width: 518px) {
    font-size: 2rem;
  }
`;

export const Description = styled.p`
  font-size: 2rem;
  font-family: "Shadows Into Light", serif;
  font-weight: 400;
  font-style: normal;

  @media (max-width: 995px) {
    font-size: 1rem;
  }

  @media (max-width: 518px) {
    font-size: 0.75rem;
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  flex-direction: row;
  transition: transform 0.5s ease-in-out;
  width: 100%;
`;

export const ImageWrapper = styled.div`
  min-width: 100%;
  height: 400px;
  position: relative;
  flex-shrink: 0;
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(80, 128, 180, 500);
    mix-blend-mode: multiply;
    pointer-events: none;
  }
  @media (max-width: 518px) {
    height: 200px;
  }
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
