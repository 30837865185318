import styled, { keyframes } from "styled-components";
interface HeaderProps {
  backgroundColor: string;
}

interface HeaderAnimationProps {
  linearGradient: string[];
}

export const Container = styled.div`
  width: 100%;
`;

export const Header = styled.header<HeaderProps>`
  background-color: ${(props) => props.backgroundColor};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
  height: 120px;
  position: sticky;
  top: 0;
  z-index: 9999;
`;

export const ContainerLogo = styled.div`
  display: flex;
  align-items: center;
  font-size: 2rem;
  color: ${(props) => props.theme.colors.text};

  &:hover {
    color: ${(props) => props.theme.colors.secondary};
  }
`;

export const Logo = styled.img`
  height: 120px;
  padding: 10px;

  @media (max-width: 600px) {
    height: 80px;
  }

  @media (max-width: 360px) {
    height: 70px;
  }
`;

export const Navigation = styled.nav`
  display: flex;
  align-items: center;
  font-family: ${(props) => props.theme.fonts.title};
`;

const getLinearGradient = (linearGradient: string[]) => {
  return `linear-gradient(-45deg, ${linearGradient.join(', ')})`;
};

export const HeaderAnimation = styled.header<HeaderAnimationProps>`
  background-size: 200% 200%;
  animation: ${keyframes`
    0% {
      background-position: 0% 50%;
    }
    100% {
      background-position: 100% 50%;
    }
  `} 2s linear infinite alternate;
  background-image: ${(props) => getLinearGradient(props.linearGradient)};
  width: 100%;
  height: 5px;
`;
