import { lazy } from "react";

const Home = lazy(async () => await import("./Home"));
const Systems = lazy(async () => await import("./Systems"));
const DucadoVerona = lazy(async () => await import("./Sistemas/DucadoVerona"));
const MelodiaPerdida = lazy(async () => await import("./Sistemas/MelodiaPerdida"));
const Pugmire = lazy(async () => await import("./Sistemas/Pugmire"));
const MorkBorg = lazy(async () => await import("./Sistemas/MorkBorg"));

export { Home, Systems, DucadoVerona, MelodiaPerdida, Pugmire, MorkBorg };
