import styled from "styled-components";
import { Link } from "react-router-dom";


export const GridContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;

export const LinkRedirect = styled(Link)`
  text-decoration: none;
`;

export const GridItem = styled.div`
  border-radius: 10px;
  cursor: pointer;
  transition: transform 0.3s, filter 0.3s;
  display: flex;
  width: 350px;
  &:hover {
    transform: scale(1.03);
  }
`;

export const Image = styled.img`
  border-radius: 10px;
  width: 100%;
  height: 165px;
  filter: grayscale(80%);
  transition: filter 0.3s;

  ${GridItem}:hover & {
    filter: grayscale(0%);
  }
`;
