import { createGlobalStyle } from "styled-components";
import Augusta from "../assets/fonts/Ducado_Verona/Augusta.ttf";
import Bell from "../assets/fonts/Ducado_Verona/BELL.TTF";
import Mevno from "../assets/fonts/Ducado_Verona/mevno1.ttf";

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Augusta';
    src: url(${Augusta}) format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Bell';
    src: url(${Bell}) format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Mevno';
    src: url(${Mevno}) format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  * {
    border: 0;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    max-width: 100%;
  }

  body {
    background-color:#FCFCF9;
    overflow-x: hidden;
    width: 100%;
  }
`;
